body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'Aktiv Grotesk';
    src: url('./type/AktivGrotesk-Light.woff2') format('woff2'),
        url('./type/AktivGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Times Now';
    src: url('./type/TimesNow-ExtraLight.woff2') format('woff2'),
        url('./type/TimesNow-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Canela';
    src: url('./type/Canela-Regular.woff2') format('woff2'),
        url('./type/Canela-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

.ant-rate-star-zero svg path {
  fill: #CCC !important;
}

.item-modal .ant-list-item {
  display: block !important;
}

.pac-container:after{display:none !important;}
